import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Button,
} from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import { StyledFiltersContainer } from '../../components/Styled';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { getSearchParams } from '../../utils';
import FiltersButton from '../../components/FiltersButton/FiltersButton';
import moment from 'moment';

const Messages = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [messages, setMessages] = useState([]);

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/messages/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, totalRecords } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setTotalRecords(totalRecords);
            setMessages(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(searchQuery);
  };

  const resetFilters = () => {
    setSearchQuery('');
    readData();
  };

  const filterData = (e) => {
    e.preventDefault();
    readData(searchQuery);
  };

  useEffect(() => {
    readData();
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='Search mobile number'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type='button'
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && messages.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          messages.length === 0
        }
        message={`No messages found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {messages.length > 0 && (
        <>
          <TableBuilder
            isLoading={messages.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'Senders Number',
                dataSelector: 'sendersNumber',
                dataType: 'string',
              },
              {
                title: 'Receivers Number',
                dataSelector: 'receiversNumber',
                dataType: 'string',
              },
              {
                title: 'Message Type',
                dataSelector: 'messageType',
                dataType: 'string',
              },
              {
                title: 'Message',
                dataSelector: 'message',
                dataType: 'string',
                width: 300,
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Status',
                dataSelector: 'messageStatus',
                dataType: 'string',
              },
              {
                title: 'Failure Reason',
                dataSelector: 'failureReason',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Created On',
                dataSelector: 'createdOn',
                dataType: 'date',
              },
            ]}
            tableData={messages}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(Messages);
