import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import Footer from '../../components/Footer/Footer';
import {
  Form,
  Fieldset,
  Label,
  NumberInput,
  PasswordInput,
  Button,
  MessageBox,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const StyledAuthContainer = styled.section`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #cdcdcd;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledAuthArea = styled.section`
  width: 500px;
  height: auto;
  min-height: 602px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #111b21;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    padding: 0px;
    height: auto;
    margin: 0px;
  }
`;

const StyledHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 20px 0px;
  background-color: #00bf94;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px 30px 0px;
  @media (max-width: 900px) {
    max-width: 400px;
    margin: 0 auto;
  }
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLinkAsButton = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledFooter = styled.section`
  width: calc(100% - 60px);
  height: auto;
  text-align: center;
  margin: 0px;
  padding: 30px;
  footer {
    padding: 0px;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const StyledSectionOne = styled.section`
  width: 100%;
  height: 75%;
  background-color: #11846a;
  position: absolute;
  top: 0;
  left: 0;
`;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [reponseMessage, setResponseMessage] = useState('');
  const [routeStage, setRouteStage] = useState('FORGOT_PASSWORD');
  const [mobileNumber, setMobileNumber] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetForm = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
    setRouteStage('FORGOT_PASSWORD');
    setRequestToken('');
    setOneTimePassword('');
    setPassword('');
    setConfirmPassword('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (routeStage === 'FORGOT_PASSWORD') {
      API.post('/auth/forgot-password', { mobileNumber })
        .then((response) => {
          const { status, message, token } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          setRequestToken(token);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setRouteStage('OTP');
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'OTP') {
      API.post('/auth/verify-otp', {
        requestType: 'RESET_PASSWORD',
        requestToken,
        mobileNumber,
        oneTimePassword,
      })
        .then((response) => {
          const { status, message, token } = response.data;
          setRequestToken(token);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setRouteStage('RESET_PASSWORD');
          } else {
            setResponseStatus(status);
            setResponseMessage(message);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'RESET_PASSWORD') {
      API.post('/auth/reset-password', {
        requestToken,
        password,
        confirmPassword,
      })
        .then((response) => {
          const { status, message, token } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setMobileNumber('');
            setRouteStage('FORGOT_PASSWORD');
            setTimeout(() => {
              resetForm();
              window.location.href = '/login';
            }, 3000);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/auth/resend-otp', {
      requestType: 'RESET_PASSWORD',
      requestToken,
      mobileNumber,
    })
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StyledAuthContainer>
      <StyledSectionOne />
      <StyledAuthArea>
        <StyledHeader>
          <Logo type='auth' />
        </StyledHeader>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'FORGOT_PASSWORD'
              ? `Forgot Password ?`
              : routeStage === 'OTP'
              ? `Verify & Reset Password`
              : `Change Password`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage === 'FORGOT_PASSWORD' && (
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter your mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={isLoading}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            )}
            {routeStage === 'OTP' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{mobileNumber}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <NumberInput
                    placeholder='Please enter your otp'
                    value={oneTimePassword}
                    onChange={setOneTimePassword}
                    maxLength={6}
                    autoComplete='off'
                    disabled={isLoading}
                    pattern={`[0-9]*`}
                    textAlign='center'
                  />
                </Fieldset>
              </>
            )}
            {routeStage === 'RESET_PASSWORD' && (
              <>
                <Fieldset>
                  <Label required>Password</Label>
                  <PasswordInput
                    placeholder='Please enter your password'
                    value={password}
                    onChange={setPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
                <Fieldset>
                  <Label required>Confirm Password</Label>
                  <PasswordInput
                    placeholder='Please re-enter your password'
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={reponseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading} width='100%'>
                {routeStage === 'FORGOT_PASSWORD'
                  ? `Send OTP`
                  : routeStage === 'OTP'
                  ? `Verify & Proceed`
                  : `Change Password`}
              </Button>
              {routeStage === 'OTP' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
            </Fieldset>
          </Form>
          {routeStage === 'FORGOT_PASSWORD' && (
            <StyleLinkAsButton to='/login' title='Login'>
              &larr; Back to Login
            </StyleLinkAsButton>
          )}
        </StyledBody>
        <StyledFooter>
          <Footer />
        </StyledFooter>
      </StyledAuthArea>
    </StyledAuthContainer>
  );
};

export default ForgotPassword;
