import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  MessageBox,
  Dropdown,
  Button,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { StyledSpan } from '../../components/Styled';

const StyledGroupsMessagesContainer = styled.section`
  height: 500px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin: 0px auto;
  border-radius: 10px;
`;

const StyledGroupsContainer = styled.section`
  width: 500px;
  border-right: 1px solid #e5e5e5;
  background-color: #f7f7f7;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
`;

const StyledGroupHeading = styled.section`
  width: 100%;
  display: flex;
  background-color: rgb(16, 132, 106);
  border-top-left-radius: 9px;
  h1 {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    padding: 20px;
    margin: 0px;
    flex: 1;
  }
`;

const StyledGroupLists = styled.section`
  height: calc(100% - 89.5px);
  padding: 15px;
  overflow-y: scroll;
`;

const StyledGroupList = styled.section`
  width: calc(100% - 32px);
  height: auto;
  margin-bottom: 15px;
  border: 1px solid rgb(224 224 224);
  padding: 15px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
`;

const StyledMessageBoxContainer = styled.section`
  flex: 1;
  padding: 20px;
`;

const SendGroupMessage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [messageType, setMessageType] = useState('');
  const [image, setImage] = useState(null);
  const [base64Image, setBase64Image] = useState('');
  const [caption, setCaption] = useState('');
  const [message, setMessage] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setSelectedGroups([]);
    setMessageType('');
    setImage('');
    setBase64Image('');
    setCaption('');
    setMessage('');
  };

  const readGroups = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/whatsapp/groups')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setGroups(data);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/whatsapp/send-group-message', {
      messageType,
      selectedGroups,
      base64Image,
      caption,
      message,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const selectGroup = (item) => {
    let newItems = [...selectedGroups];
    const index = newItems.findIndex((item2) => item2.id === item.id);
    if (index === -1) {
      newItems.push(item);
    } else {
      newItems = newItems.filter((item2) => item2.id !== item.id);
    }
    setSelectedGroups(newItems);
  };

  const selectAllGroups = (e) => {
    e.preventDefault();
    setSelectedGroups([...groups]);
  };

  const clearAllgroups = (e) => {
    e.preventDefault();
    setSelectedGroups([]);
  };

  useEffect(() => {
    readGroups();
  }, []);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        setBase64Image(reader.result);
      };
    }
  }, [image]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer style={{ padding: '0px', width: '100%' }}>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <StyledGroupsMessagesContainer>
            <StyledGroupsContainer>
              <StyledGroupHeading>
                <h1>Select Groups</h1>
                <Button
                  type='button'
                  onClick={
                    groups.length === selectedGroups.length
                      ? clearAllgroups
                      : selectAllGroups
                  }
                  style={{ margin: '10px', padding: '0px 35px 0px 35px' }}
                >
                  {groups.length === selectedGroups.length
                    ? 'Clear All'
                    : 'Select All'}
                </Button>
              </StyledGroupHeading>
              <StyledGroupLists>
                {groups.length > 0 &&
                  groups.map((item, index) => (
                    <StyledGroupList
                      key={index}
                      onClick={() => selectGroup(item)}
                      style={{
                        backgroundColor: selectedGroups.some(
                          (item2) => item2.id === item.id
                        )
                          ? '#7bc8ff'
                          : '#ffffff',
                      }}
                    >
                      {item.groupName}
                    </StyledGroupList>
                  ))}
              </StyledGroupLists>
            </StyledGroupsContainer>
            <StyledMessageBoxContainer>
              <Fieldset>
                <Label required>Message Type</Label>
                <Dropdown
                  placeholder='Message Type'
                  value={messageType}
                  onChange={setMessageType}
                  options={[
                    {
                      title: '-- SELECT MESSAGE TYPE --',
                      value: '',
                    },
                    {
                      title: 'Image',
                      value: 'Image',
                    },
                    {
                      title: 'Text',
                      value: 'Text',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
              {messageType === 'Image' && (
                <>
                  <Fieldset>
                    <Label required>Select Image</Label>
                    <input
                      type='file'
                      name='image'
                      id='image'
                      onChange={(e) => setImage(e.target.files[0])}
                      disabled={isLoading}
                      accept='.png, .jpeg, .jpg'
                      style={{
                        display: 'block',
                        width: 'calc(100% - 42px)',
                        height: 'auto',
                        fontSize: '14px',
                        padding: '20px',
                        border: '1px solid rgb(229, 229, 229)',
                        borderRadius: '5px',
                        outlineColor: 'rgb(17, 132, 106)',
                        backgroundColor: 'rgb(255, 255, 255)',
                      }}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label>Caption</Label>
                    <textarea
                      value={caption}
                      onChange={(e) => setCaption(e.target.value)}
                      placeholder='Please enter image caption'
                      disabled={isLoading}
                      style={{
                        display: 'block',
                        width: 'calc(100% - 42px)',
                        minHeight: 120,
                        maxHeight: 120,
                        fontSize: '14px',
                        padding: '20px',
                        border: '1px solid rgb(229, 229, 229)',
                        borderRadius: '5px',
                        outlineColor: 'rgb(17, 132, 106)',
                        backgroundColor: 'rgb(255, 255, 255)',
                      }}
                    />
                  </Fieldset>
                </>
              )}
              {messageType === 'Text' && (
                <Fieldset>
                  <Label required>Message</Label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Please enter message'
                    disabled={isLoading}
                    style={{
                      display: 'block',
                      width: 'calc(100% - 42px)',
                      minHeight: 200,
                      maxHeight: 200,
                      fontSize: '14px',
                      padding: '20px',
                      border: '1px solid rgb(229, 229, 229)',
                      borderRadius: '5px',
                      outlineColor: 'rgb(17, 132, 106)',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  />
                  <StyledSpan style={{ color: 'red' }}>
                    Note : To send text in bold wrap the desired text with{' '}
                    <b>*</b>. Example : *Hello* will ouput <b>Hello</b>
                  </StyledSpan>
                </Fieldset>
              )}
              {responseMessage && (
                <Fieldset>
                  <MessageBox
                    status={responseStatus}
                    message={responseMessage}
                  />
                </Fieldset>
              )}
              <Fieldset>
                <Button>Send</Button>
              </Fieldset>
            </StyledMessageBoxContainer>
          </StyledGroupsMessagesContainer>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SendGroupMessage);
