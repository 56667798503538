const MODULES = {
  DASHBOARD: 'DASHBOARD',
  WHATSAPP_CONFIGURATION: 'WHATSAPP_CONFIGURATION',
  ACCOUNTS: 'ACCOUNTS',
  PLANS: 'PLANS',
  SCROLL_MESSAGE: 'SCROLL_MESSAGE',
  SEND_BULK_MESSAGE: 'SEND_BULK_MESSAGE',
  SEND_GROUP_MESSAGE: 'SEND_GROUP_MESSAGE',
  MESSAGES: 'MESSAGES',
  TRANSACTIONS: 'TRANSACTIONS',
  API_SETTINGS: 'API_SETTINGS',
  API_DOCUMENTATION: 'API_DOCUMENTATION',
};

const ROUTE_TYPES = {
  ALLOW_IF_LOGGEDIN: 'ALLOW_IF_LOGGEDIN',
  ALLOW_IF_NOT_LOGGEDIN: 'ALLOW_IF_NOT_LOGGEDIN',
};

const PERMISSION_TYPES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  EXTEND: 'EXTEND',
};

const ACCOUNT_TYPES = {
  ADMIN: 'Admin',
  USER: 'User',
};

const API_RESPONSE_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'failed',
};

const VIEW_TYPES = {
  TABLE: 'TABLE',
  GRID: 'GRID',
};

export {
  MODULES,
  ROUTE_TYPES,
  PERMISSION_TYPES,
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  VIEW_TYPES,
};
