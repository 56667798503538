import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  MessageBox,
  Button,
  TextInput,
  PasswordInput,
  Legend,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const StyledCallbackUrl = styled.span`
  font-size: 12px;
  display: block;
  color: #848484;
  margin-top: 5px;
  font-style: italic;
  text-transform: capitalize;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 20px 0px 0px 0px;
  padding: 10px;
  line-height: 20px;
  color: #000000;
  background-color: #f3f3f3;
  text-transform: inherit;
  font-style: normal;
`;

const APISettings = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoadingG, setIsLoadingG] = useState(false);
  const [responseStatusG, setResponseStatusG] = useState('');
  const [responseMessageG, setResponseMessageG] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [callBackUrl, setCallBackUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiToken, setApiToken] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/auth/api-settings')
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          const { ipAddress, apiKey, apiToken, callBackUrl } = data;
          setIpAddress(ipAddress);
          setCallBackUrl(callBackUrl);
          setApiKey(apiKey);
          setApiToken(apiToken);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put('/auth/api-settings', {
      ipAddress,
      callBackUrl,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateApiKeys = (e) => {
    e.preventDefault();
    setIsLoadingG(true);
    setResponseStatusG('');
    setResponseMessageG('');
    API.get(`/auth/generate-api-keys`)
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseStatusG(status);
        setResponseMessageG(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          const { apiKey, apiToken } = data;
          setApiKey(apiKey);
          setApiToken(apiToken);
          setTimeout(() => {
            setResponseStatusG('');
            setResponseMessageG('');
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatusG(API_RESPONSE_TYPES.FAILURE);
        setResponseMessageG(error.message);
      })
      .finally(() => {
        setIsLoadingG(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer style={{ marginBottom: '20px' }}>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset forLegend>
            <Legend>Whitelist IP Address</Legend>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>Server IP</Label>
                  <TextInput
                    placeholder='Please enter your server ip OR 0.0.0.0 to accept requests from anywhere'
                    value={ipAddress}
                    onChange={setIpAddress}
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Callback URL</Label>
                  <TextInput
                    placeholder='Please enter your callback url'
                    value={callBackUrl}
                    onChange={setCallBackUrl}
                    disabled={isLoading}
                  />
                  <StyledCallbackUrl>
                    {`Example : ${
                      callBackUrl
                        ? callBackUrl
                        : `https://www.yourdomain.com/message_status_callback`
                    }?messagesId=[YOUR_MESSAGES_ID]&receiversNumber=[RECEIVERS_NUMBER]&message=[YOUR_MESSAGE]&status=[STATUS]`}
                  </StyledCallbackUrl>
                </Fieldset>
              </GridItem>
            </Grid>
            {responseStatus && (
              <Grid
                columns={
                  'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
                }
              >
                <GridItem>
                  <Fieldset>
                    <MessageBox
                      status={responseStatus}
                      message={responseMessage}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
            )}
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset style={{ marginBottom: '0px' }}>
                  <Button disabled={isLoading} type='submit' width='250px'>
                    Update
                  </Button>
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
      <FormContainer>
        <Form method='POST' action='#' onSubmit={generateApiKeys}>
          <Fieldset forLegend>
            <Legend>API Keys</Legend>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>API Key</Label>
                  <PasswordInput
                    placeholder='Your API ID'
                    value={apiKey}
                    autoComplete='off'
                    disabled={true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>API Token</Label>
                  <PasswordInput
                    placeholder='Your API Key'
                    value={apiToken}
                    autoComplete='off'
                    disabled={true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            {responseStatusG && (
              <Grid
                columns={
                  'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
                }
              >
                <GridItem>
                  <Fieldset>
                    <MessageBox
                      status={responseStatusG}
                      message={responseMessageG}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
            )}
            <Fieldset style={{ marginBottom: '0px' }}>
              <Button disabled={isLoadingG} type='submit' width='250px'>
                Generate New Keys
              </Button>
            </Fieldset>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoadingG} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APISettings);
