import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const StyledPasswordContainer = styled.section`
  width: auto;
  height: auto;
  position: relative;
  svg {
    position: absolute;
    top: 19px;
    right: 20px;
    color: #a9a9a9;
    font-size: 20px;
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  display: block;
  width: calc(100% - 82px);
  height: auto;
  font-size: 14px;
  padding: 20px 60px 20px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  outline-color: #11846a;
  background-color: #ffffff;
  &:disabled {
    cursor: not-allowed;
    background-color: #fafafa;
  }
`;

const PasswordInput = ({
  value = '',
  onChange = () => {},
  maxLength = 1000,
  focus = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [inputType, setInputType] = useState('password');

  const toggleInputType = () => {
    setInputType((inputType) =>
      inputType === 'password' ? 'text' : 'password'
    );
  };

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  return (
    <StyledPasswordContainer>
      <StyledInput
        type={inputType}
        value={value}
        onChange={(e) =>
          onChange(e.target.value.length <= maxLength ? e.target.value : value)
        }
        ref={inputRef}
        autoComplete="off"
        {...rest}
      />
      <FontAwesomeIcon
        icon={inputType === 'password' ? faEye : faEyeSlash}
        onClick={toggleInputType}
      />
    </StyledPasswordContainer>
  );
};

export default PasswordInput;
