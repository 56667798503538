import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Legend,
} from '../../components/FormElements';
import { StyledMicroButton } from '../../components/Styled';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const CommonAPISConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [whatsappApiEndpoint, setWhatsappApiEndpoint] = useState('');
  const [whatsappApiRequestMethod, setWhatsappApiRequestMethod] = useState('');
  const [whatsappApiRequestBody, setWhatsappApiRequestBody] = useState('');
  const [whatsappApiResponseType, setWhatsappApiResponseType] = useState('');
  const [whatsappApiStatusKey, setWhatsappApiStatusKey] = useState('');
  const [whatsappApiSuccessKey, setWhatsappApiSuccessKey] = useState('');
  const [whatsappApiFailureKey, setWhatsappApiFailureKey] = useState('');
  const [whatsAppRegards, setWhatsAppRegards] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      whatsappApiEndpoint,
      whatsappApiRequestMethod,
      whatsappApiRequestBody,
      whatsappApiResponseType,
      whatsappApiStatusKey,
      whatsappApiSuccessKey,
      whatsappApiFailureKey,
      whatsAppRegards,
    } = originalFormData;
    setWhatsappApiEndpoint(whatsappApiEndpoint ? whatsappApiEndpoint : '');
    setWhatsappApiRequestMethod(
      whatsappApiRequestMethod ? whatsappApiRequestMethod : ''
    );
    setWhatsappApiRequestBody(
      whatsappApiRequestBody ? whatsappApiRequestBody : ''
    );
    setWhatsappApiResponseType(
      whatsappApiResponseType ? whatsappApiResponseType : ''
    );
    setWhatsappApiStatusKey(whatsappApiStatusKey ? whatsappApiStatusKey : '');
    setWhatsappApiSuccessKey(
      whatsappApiSuccessKey ? whatsappApiSuccessKey : ''
    );
    setWhatsappApiFailureKey(
      whatsappApiFailureKey ? whatsappApiFailureKey : ''
    );
    setWhatsAppRegards(whatsAppRegards ? whatsAppRegards : '');
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/common-apis`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            whatsappApiEndpoint,
            whatsappApiRequestMethod,
            whatsappApiRequestBody,
            whatsappApiResponseType,
            whatsappApiStatusKey,
            whatsappApiSuccessKey,
            whatsappApiFailureKey,
            whatsAppRegards,
          } = data;
          setWhatsappApiEndpoint(
            whatsappApiEndpoint ? whatsappApiEndpoint : ''
          );
          setWhatsappApiRequestMethod(
            whatsappApiRequestMethod ? whatsappApiRequestMethod : ''
          );
          setWhatsappApiRequestBody(
            whatsappApiRequestBody ? whatsappApiRequestBody : ''
          );
          setWhatsappApiResponseType(
            whatsappApiResponseType ? whatsappApiResponseType : ''
          );
          setWhatsappApiStatusKey(
            whatsappApiStatusKey ? whatsappApiStatusKey : ''
          );
          setWhatsappApiSuccessKey(
            whatsappApiSuccessKey ? whatsappApiSuccessKey : ''
          );
          setWhatsappApiFailureKey(
            whatsappApiFailureKey ? whatsappApiFailureKey : ''
          );
          setWhatsAppRegards(whatsAppRegards ? whatsAppRegards : '');
          setOriginalFormData({
            whatsappApiEndpoint,
            whatsappApiRequestMethod,
            whatsappApiRequestBody,
            whatsappApiResponseType,
            whatsappApiStatusKey,
            whatsappApiSuccessKey,
            whatsappApiFailureKey,
            whatsAppRegards,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/common-apis`, {
      whatsappApiEndpoint,
      whatsappApiRequestMethod,
      whatsappApiRequestBody,
      whatsappApiResponseType,
      whatsappApiStatusKey,
      whatsappApiSuccessKey,
      whatsappApiFailureKey,
      whatsAppRegards,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData({
            whatsappApiEndpoint,
            whatsappApiRequestMethod,
            whatsappApiRequestBody,
            whatsappApiResponseType,
            whatsappApiStatusKey,
            whatsappApiSuccessKey,
            whatsappApiFailureKey,
            whatsAppRegards,
          });
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset forLegend>
            <Legend>Whats APP API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={whatsappApiEndpoint}
                onChange={setWhatsappApiEndpoint}
                placeholder='Please enter api endpoint'
                disabled={isLoading === true}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={whatsappApiRequestBody}
                onChange={setWhatsappApiRequestBody}
                placeholder='Please enter request body'
                disabled={isLoading === true}
              />
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setWhatsappApiRequestBody, '{{{NUMBER}}}')
                }
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading === true}
                type='button'
                onClick={() =>
                  addText(setWhatsappApiRequestBody, '{{{MESSAGE}}}')
                }
              >
                Message
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={whatsappApiRequestMethod}
                    onChange={setWhatsappApiRequestMethod}
                    placeholder='Please select request method'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API REQUEST METHOD --',
                        value: '',
                      },
                      {
                        title: 'POST',
                        value: 'POST',
                      },
                      {
                        title: 'GET',
                        value: 'GET',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={whatsappApiResponseType}
                    onChange={setWhatsappApiResponseType}
                    placeholder='Please select response type'
                    disabled={isLoading === true}
                    options={[
                      {
                        title: '-- SELECT API RESPONSE TYPE --',
                        value: '',
                      },
                      {
                        title: 'JSON',
                        value: 'JSON',
                      },
                      {
                        title: 'XML',
                        value: 'XML',
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={whatsappApiStatusKey}
                    onChange={setWhatsappApiStatusKey}
                    placeholder='Please enter status key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Key</Label>
                  <TextInput
                    value={whatsappApiSuccessKey}
                    onChange={setWhatsappApiSuccessKey}
                    placeholder='Please enter success key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failure Key</Label>
                  <TextInput
                    value={whatsappApiFailureKey}
                    onChange={setWhatsappApiFailureKey}
                    placeholder='Please enter failure key'
                    disabled={isLoading === true}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Company Name ( Registered Firm Name )</Label>
              <TextInput
                value={whatsAppRegards}
                onChange={setWhatsAppRegards}
                placeholder='Please enter company name'
                disabled={isLoading === true}
              />
            </Fieldset>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type='submit'>
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type='button'
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(CommonAPISConfiguration);
