import axios from 'axios';
import APIConfig from '../constants/api.config';
import {
  authData,
  getPlatform,
  getDeviceType,
  getOperatingSystem,
} from '../utils';

const { API_BASE_URL, HEADERS } = APIConfig;
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const { isLoggedIn, authId, authToken } = authData.get();
    request.maxBodyLength = Infinity;
    request.headers['X-Platform'] = getPlatform();
    request.headers['X-DeviceType'] = getDeviceType();
    request.headers['X-OperatingSystem'] = getOperatingSystem();
    if (isLoggedIn) {
      request.headers['X-AuthId'] = authId;
      request.headers['X-AuthToken'] = authToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.status === 'expired') {
      authData.clear();
      window.location.href = '/login';
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Api = {
  post: (url, data, headers = HEADERS.POST) => {
    return axiosInstance.post(url, data, headers);
  },
  get: (url, headers = HEADERS.GET) => {
    return axiosInstance.get(url, headers);
  },
  put: (url, data, headers = HEADERS.PUT) => {
    return axiosInstance.put(url, data, headers);
  },
  delete: (url, headers = HEADERS.DELETE) => {
    return axiosInstance.delete(url, headers);
  },
};

export default Api;
