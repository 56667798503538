import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Button,
  CustomConfirmButton,
} from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import { StyledLink, StyledFiltersContainer } from '../../components/Styled';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../constants';
import { getSearchParams, formatCurrency } from '../../utils';
import FiltersButton from '../../components/FiltersButton/FiltersButton';

const PlansRead = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [plans, setPlans] = useState([]);

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/plans/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, totalRecords } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setTotalRecords(totalRecords);
            setPlans(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(`/plans/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(searchQuery);
  };

  const resetFilters = () => {
    setSearchQuery('');
    readData();
  };

  const filterData = (e) => {
    e.preventDefault();
    readData(searchQuery);
  };

  useEffect(() => {
    readData();
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;

  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='Search plan name'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type='button'
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && plans.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          plans.length === 0
        }
        message={`No plans found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {plans.length > 0 && (
        <>
          <TableBuilder
            isLoading={plans.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                align: 'center',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Plan Name',
                dataSelector: 'planName',
                dataType: 'string',
              },
              {
                title: 'Duration',
                dataSelector: 'duration',
                dataType: 'number',
                CellRenderer: (value) => (value ? `${value} Days` : 'N/A'),
              },
              {
                title: 'Price',
                dataSelector: 'price',
                dataType: 'number',
                CellRenderer: (value) =>
                  value ? formatCurrency(value) : formatCurrency(0),
              },
            ]}
            tableData={plans}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(PlansRead);
