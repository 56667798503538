import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo/Logo';
import Header from '../components/Header/Header';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import ScrollMessage from '../components/ScrollMessage/ScrollMessage';
import API from '../api';
import { API_RESPONSE_TYPES } from '../constants';
import { authData } from '../utils';

const StyledMain = styled.main`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
`;

const withAuthHOC = (WrappedComponent) => {
  return (props) => {
    return (
      <>
        <Logo />
        <WrappedComponent {...props} />
      </>
    );
  };
};

const withAppHOC = (WrappedComponent) => {
  return (props) => {
    const [showNav, setShowNav] = useState(false);
    const [profileName, setProfileName] = useState('-');
    const [accountExpiry, setAccountExpiry] = useState(null);
    const [scrollMessage, setScrollMessage] = useState('');

    const onClickLogout = (e) => {
      e.preventDefault();
      const { authToken } = authData.get();
      API.delete(`/auth/logout/?sessionToken=${authToken}`);
      authData.clear();
      window.location.href = '/login';
    };

    const readProfile = useCallback(() => {
      API.get(`/auth/profile`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setProfileName(`${data.firstName} ${data.lastName}` || 'N/A');
          setAccountExpiry(data.accountExpiry);
          authData.set('accountData', JSON.stringify(data));
        }
      });
    }, []);

    const readScrollMessage = useCallback(() => {
      API.get(`/scroll-message`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setScrollMessage(data);
        }
      });
    }, []);

    const onClickNavigationIcon = () => {
      setShowNav((showNav) => !showNav);
    };

    useEffect(() => {
      readProfile();
      readScrollMessage();
    }, [readProfile, readScrollMessage]);

    return (
      <>
        <Header
          supportMobileNumber={'9765847575'}
          supportEmailAddress={'info.janheet@gmail.com'}
          fullName={profileName}
          onClickLogout={onClickLogout}
          showNav={showNav}
          setShowNav={onClickNavigationIcon}
        />
        <Navigation showNav={showNav} />
        <ScrollMessage scrollMessage={scrollMessage} />
        <StyledMain>
          <WrappedComponent
            {...props}
            readProfile={readProfile}
            accountExpiry={accountExpiry}
          />
        </StyledMain>
        <Footer />
      </>
    );
  };
};

export { withAuthHOC, withAppHOC };
