import isEmpty from 'lodash/isEmpty';
import { ROUTE_TYPES } from '../constants';
import { ROUTES } from '../constants/routes.config';

const authData = {
  get: () => {
    return {
      isLoggedIn: window.localStorage.getItem('isLoggedIn') === 'true' || false,
      authId: window.localStorage.getItem('authId') || null,
      authToken: window.localStorage.getItem('authToken') || null,
      accountsPermissions:
        JSON.parse(window.localStorage.getItem('accountsPermissions')) || null,
      accountData:
        JSON.parse(window.localStorage.getItem('accountData')) || null,
    };
  },
  set: (key, value) => window.localStorage.setItem(key, value),
  clear: () => window.localStorage.clear(),
};

const getPlatform = () => {
  return 'Web';
};

const getDeviceType = () => {
  return navigator?.userAgentData?.mobile ? 'Mobile' : 'Desktop';
};

const getOperatingSystem = () => {
  return navigator?.userAgentData?.platform ?? 'Unknown';
};

const checkRoutePermission = ({
  routeType,
  moduleName,
  permissionRequired,
}) => {
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const routeTypeCheck =
    isEmpty(routeType) || (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn);
  const modulePermissionCheck =
    (routeType === ALLOW_IF_NOT_LOGGEDIN && isEmpty(moduleName)) ||
    (routeType === ALLOW_IF_LOGGEDIN &&
      !isEmpty(moduleName) &&
      !isEmpty(permissionRequired) &&
      (!isEmpty(accountsPermissions[moduleName])
        ? accountsPermissions[moduleName].includes(permissionRequired)
        : false));

  return routeTypeCheck && modulePermissionCheck;
};

const generateRoutes = () => {
  const routesList = [];

  (function loopRoutes(routes) {
    !isEmpty(routes) &&
      routes.forEach((route) => {
        route.path && routesList.push(route);
        if (!isEmpty(route.subRoutes)) {
          loopRoutes(route.subRoutes);
        }
      });
  })(ROUTES);

  return routesList;
};

const isNumber = (event) => {
    event = event ? event : window.event;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
};

const getSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const formatCurrency = (value) => {
  const rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return rupeeIndian.format(value);
};

export {
  authData,
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  checkRoutePermission,
  generateRoutes,
  getSearchParams,
  formatCurrency,
  isNumber,
};
