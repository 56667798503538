import React, { useState } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  PasswordInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const AccountsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setAccountType('');
    setFirstName('');
    setLastName('');
    setMobileNumber('');
    setAccountType('');
    setAccountStatus('');
    setPassword('');
    setConfirmPassword('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      firstName,
      lastName,
      mobileNumber,
      accountType,
      accountStatus,
      password,
      confirmPassword,
    };
    API.post('/accounts', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/accounts';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  placeholder='Please enter your first name'
                  value={firstName}
                  onChange={setFirstName}
                  maxLength={100}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  placeholder='Please enter your last name'
                  value={lastName}
                  onChange={setLastName}
                  maxLength={100}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter your mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={isLoading}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Account Type</Label>
                <Dropdown
                  placeholder='Account Type'
                  value={accountType}
                  onChange={setAccountType}
                  options={[
                    {
                      title: '-- SELECT ACCOUNT TYPE --',
                      value: '',
                    },
                    {
                      title: 'Admin',
                      value: 'Admin',
                    },
                    {
                      title: 'User',
                      value: 'User',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Status</Label>
                <Dropdown
                  placeholder='Account Status'
                  value={accountStatus}
                  onChange={setAccountStatus}
                  options={[
                    {
                      title: '-- SELECT ACCOUNT STATUS --',
                      value: '',
                    },
                    {
                      title: 'Active',
                      value: 'Active',
                    },
                    {
                      title: 'Inactive',
                      value: 'Inactive',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Password</Label>
                <PasswordInput
                  placeholder='Please enter your password'
                  value={password}
                  onChange={setPassword}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Confirm Password</Label>
                <PasswordInput
                  placeholder='Please re-enter your password'
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AccountsCreate);
