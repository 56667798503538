import React, { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Dropdown,
  MessageBox,
  Button,
  TextInput,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { StyledSpan } from '../../components/Styled';

const SendBulkMessage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [receiverNumbers, setReceiverNumbers] = useState('');
  const [receiverObjects, setReceiverObjects] = useState([]);
  const [image, setImage] = useState(null);
  const [base64Image, setBase64Image] = useState('');
  const [caption, setCaption] = useState('');
  const [message, setMessage] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setMessageType('');
    setReceiverNumbers('');
    setReceiverObjects([]);
    setImage(null);
    setBase64Image('');
    setCaption('');
    setMessage('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/whatsapp/send-bulk-message', {
      messageType,
      receiverObjects:
        receiverObjects.length > 0
          ? receiverObjects
          : receiverNumbers
              .split(',')
              .map((item) => ({ mobileNumber: parseInt(item.trim()) }))
              .filter((item) => item),
      base64Image,
      caption,
      message,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setReceiverObjects(json);
        setReceiverNumbers(json.map((item) => item.mobileNumber).join());
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        setBase64Image(reader.result);
      };
    }
  }, [image]);

  const noteMessage = `Note: To use dynamic data from excel in message body, please use the column name as variable selectors. Example - {{{COLUMN_NAME}}}`;

  return (
    <>
      <PageHeading {...props} />
      <FormContainer style={{ marginBottom: '20px' }}>
        <a href='./SampleExcel.xlsx' download='SampleExcel' target='_blank'>
          <Button>Download Sample Excel (.xlsx) File</Button>
        </a>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          style={{ marginTop: '20px' }}
        >
          <Fieldset>
            <Label required>Receiver Numbers</Label>
            <input
              type='file'
              name='upload'
              id='upload'
              onChange={readUploadFile}
              disabled={isLoading}
              accept='.xlsx'
              style={{
                display: 'block',
                width: 'calc(100% - 42px)',
                height: 'auto',
                fontSize: '14px',
                padding: '20px',
                border: '1px solid rgb(229, 229, 229)',
                borderRadius: '5px',
                outlineColor: 'rgb(17, 132, 106)',
                backgroundColor: 'rgb(255, 255, 255)',
              }}
            />
            <center>
              <Label>Or</Label>
            </center>
            <TextInput
              value={
                receiverObjects.length > 0
                  ? receiverObjects.map((item) => item.mobileNumber).join()
                  : receiverNumbers
              }
              onChange={(value) => {
                setReceiverNumbers(value);
                setReceiverObjects([]);
              }}
              placeholder='Please enter receiver numbers seperated by comma'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Message Type</Label>
            <Dropdown
              placeholder='Message Type'
              value={messageType}
              onChange={setMessageType}
              options={[
                {
                  title: '-- SELECT MESSAGE TYPE --',
                  value: '',
                },
                {
                  title: 'Image',
                  value: 'Image',
                },
                {
                  title: 'Text',
                  value: 'Text',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {messageType === 'Image' && (
            <>
              <Fieldset>
                <Label required>Select Image</Label>
                <input
                  type='file'
                  name='image'
                  id='image'
                  onChange={(e) => setImage(e.target.files[0])}
                  disabled={isLoading}
                  accept='.png, .jpeg, .jpg'
                  style={{
                    display: 'block',
                    width: 'calc(100% - 42px)',
                    height: 'auto',
                    fontSize: '14px',
                    padding: '20px',
                    border: '1px solid rgb(229, 229, 229)',
                    borderRadius: '5px',
                    outlineColor: 'rgb(17, 132, 106)',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                />
              </Fieldset>
              <Fieldset>
                <Label>Caption</Label>
                <textarea
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  placeholder='Please enter image caption'
                  disabled={isLoading}
                  style={{
                    display: 'block',
                    width: 'calc(100% - 42px)',
                    minHeight: 200,
                    maxHeight: 200,
                    fontSize: '14px',
                    padding: '20px',
                    border: '1px solid rgb(229, 229, 229)',
                    borderRadius: '5px',
                    outlineColor: 'rgb(17, 132, 106)',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                />
              </Fieldset>
            </>
          )}
          {messageType === 'Text' && (
            <Fieldset>
              <Label required>Message</Label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='Please enter message'
                disabled={isLoading}
                style={{
                  display: 'block',
                  width: 'calc(100% - 42px)',
                  minHeight: 300,
                  maxHeight: 300,
                  fontSize: '14px',
                  padding: '20px',
                  border: '1px solid rgb(229, 229, 229)',
                  borderRadius: '5px',
                  outlineColor: 'rgb(17, 132, 106)',
                  backgroundColor: 'rgb(255, 255, 255)',
                }}
              />
              <StyledSpan style={{ color: 'red' }}>
                Note : To send text in bold wrap the desired text with <b>*</b>.
                Example : *Hello* will ouput <b>Hello</b>
              </StyledSpan>
            </Fieldset>
          )}
          {responseMessage && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <Button>Send</Button>
          </Fieldset>
        </Form>
        <StyledSpan style={{ color: 'red', marginBottom: 20 }}>
          {noteMessage}
        </StyledSpan>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SendBulkMessage);
