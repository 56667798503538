import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import Footer from '../../components/Footer/Footer';
import {
  Form,
  Fieldset,
  Label,
  NumberInput,
  PasswordInput,
  Button,
  MessageBox,
  TextInput,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { authData } from '../../utils';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import axios from 'axios';
import APIConfig from '../../constants/api.config';

const StyledAuthContainer = styled.section`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #cdcdcd;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledAuthArea = styled.section`
  width: 700px;
  height: auto;
  min-height: 602px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #111b21;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    padding: 0px;
    height: auto;
    margin: 0px;
  }
`;

const StyledHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 20px 0px;
  background-color: #00bf94;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px 30px 0px;
  @media (max-width: 900px) {
    max-width: 400px;
    margin: 0 auto;
  }
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLink = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledFooter = styled.section`
  width: calc(100% - 60px);
  height: auto;
  text-align: center;
  margin: 0px;
  padding: 30px;
  footer {
    padding: 0px;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const StyledRegisterLink = styled.h2`
  display: block;
  font-size: 14px;
  margin: 0px;
  color: #000000;
  text-transform: normal;
  font-weight: normal;
  text-align: center;
  padding: 0px;
  a {
    position: static;
  }
`;

const StyledSectionOne = styled.section`
  width: 100%;
  height: 75%;
  background-color: #11846a;
  position: absolute;
  top: 0;
  left: 0;
`;

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [routeStage, setRouteStage] = useState('REGISTER');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');

  const setAuthData = (data) => {
    const {
      token,
      data: { accountsPermissions },
    } = data;
    authData.set('isLoggedIn', true);
    authData.set('authId', mobileNumber);
    authData.set('authToken', token);
    authData.set('accountsPermissions', JSON.stringify(accountsPermissions));
    authData.set('accountData', JSON.stringify(data.data));
    window.location.href = '/';
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (routeStage === 'REGISTER') {
      API.post(`/auth/register`, {
        firstName,
        lastName,
        mobileNumber,
        password,
        confirmPassword,
      })
        .then((response) => {
          const { status, message, token } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setRequestToken(token);
            setRouteStage('OTP');
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'OTP') {
      API.post('/auth/verify-otp', {
        requestType: 'REGISTER',
        requestToken,
        mobileNumber,
        oneTimePassword,
      })
        .then((response) => {
          const { status, message, token } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
            setRequestToken(token);
          } else {
            setAuthData(response.data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/auth/resend-otp', {
      requestType: 'REGISTER',
      requestToken,
      mobileNumber,
    })
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setRequestToken(token);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StyledAuthContainer>
      <StyledSectionOne />
      <StyledAuthArea>
        <StyledHeader>
          <Logo type='auth' />
        </StyledHeader>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'REGISTER'
              ? `Create your Account`
              : `Verify & Login`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage === 'REGISTER' && (
              <>
                <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                  <GridItem>
                    <Fieldset>
                      <Label required>First Name</Label>
                      <TextInput
                        placeholder='Please enter your first name'
                        value={firstName}
                        onChange={setFirstName}
                        maxLength={100}
                        autoComplete='off'
                        disabled={isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset>
                      <Label required>Last Name</Label>
                      <TextInput
                        placeholder='Please enter your last name'
                        value={lastName}
                        onChange={setLastName}
                        maxLength={100}
                        autoComplete='off'
                        disabled={isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
                <Fieldset>
                  <Label required>Mobile Number</Label>
                  <NumberInput
                    placeholder='Please enter your mobile number'
                    value={mobileNumber}
                    onChange={setMobileNumber}
                    maxLength={10}
                    autoComplete='off'
                    disabled={isLoading}
                    pattern={`[0-9]*`}
                  />
                </Fieldset>
                <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                  <GridItem>
                    <Fieldset>
                      <Label required>Password</Label>
                      <PasswordInput
                        placeholder='Please enter your password'
                        value={password}
                        onChange={setPassword}
                        autoComplete='off'
                        disabled={isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset>
                      <Label required>Confirm Password</Label>
                      <PasswordInput
                        placeholder='Please re-enter your password'
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        autoComplete='off'
                        disabled={isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
              </>
            )}
            {routeStage === 'OTP' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{mobileNumber}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <NumberInput
                    placeholder='Please enter your otp'
                    value={oneTimePassword}
                    onChange={setOneTimePassword}
                    maxLength={6}
                    autoComplete='off'
                    disabled={isLoading}
                    pattern={`[0-9]*`}
                    textAlign='center'
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={responseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading} width='100%'>
                {routeStage === 'REGISTER' ? `Register` : `Verify & Proceed`}
              </Button>
              {routeStage === 'OTP' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
            </Fieldset>
          </Form>
          {routeStage === 'REGISTER' && (
            <StyledRegisterLink>
              Already have an account ?{' '}
              <StyleLink to='/login' title='Login'>
                Login
              </StyleLink>
            </StyledRegisterLink>
          )}
        </StyledBody>
        <StyledFooter>
          <Footer />
        </StyledFooter>
        <OverlayLoader showLoader={isLoading} />
      </StyledAuthArea>
    </StyledAuthContainer>
  );
};

export default Register;
