import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import GlobalStyles from './styles';
import { ROUTE_TYPES } from './constants';
import { authData, generateRoutes } from './utils';

const App = () => {
  const routesList = generateRoutes();
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const theme = {};

  const ProcessedRoute = ({
    component: Component,
    routeType,
    moduleName,
    permissionRequired,
    ...rest
  }) => {
    let ProcessedComponent;

    if (!isEmpty(routeType)) {
      if (isLoggedIn && accountsPermissions === null) {
        authData.clear();
        window.location.href = '/login';
      } else {
        if (
          (routeType === ALLOW_IF_NOT_LOGGEDIN && !isLoggedIn) ||
          (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn)
        ) {
          if (
            isEmpty(moduleName) ||
            (!isEmpty(moduleName) &&
              !isEmpty(accountsPermissions[moduleName]) &&
              accountsPermissions[moduleName].includes(permissionRequired))
          ) {
            ProcessedComponent = (
              <Component
                accountsPermissions={
                  !isEmpty(moduleName) &&
                  accountsPermissions !== null &&
                  accountsPermissions[moduleName]
                }
                routeType={!isEmpty(routeType) && routeType}
                moduleName={!isEmpty(moduleName) && moduleName}
                permissionRequired={
                  !isEmpty(permissionRequired) && permissionRequired
                }
                {...rest}
              />
            );
          } else {
            ProcessedComponent = <Navigate to="/" />;
          }
        } else {
          ProcessedComponent = (
            <Navigate to={routeType === ALLOW_IF_LOGGEDIN ? '/login' : '/'} />
          );
        }
      }
    } else {
      ProcessedComponent = <Component {...rest} />;
    }

    return ProcessedComponent;
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          {routesList.map(
            (
              {
                path,
                routeType,
                moduleName,
                permissionRequired,
                component,
                ...rest
              },
              index
            ) =>
              path && (
                <Route
                  path={path}
                  element={
                    <ProcessedRoute
                      routeType={routeType}
                      moduleName={moduleName}
                      permissionRequired={permissionRequired}
                      component={component}
                      {...rest}
                    />
                  }
                  key={index}
                />
              )
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
