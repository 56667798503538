import React from 'react';
import styled from 'styled-components';

const StyledLogoContainer = styled.section`
  width: auto;
  height: ${({ type }) => (type === 'auth' ? 'auto' : '55px')};
  margin: 0px auto;
  padding: 0px 5px;
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: ${({ type }) => (type === 'auth' ? 'auto' : '100%')};
  height: 100%;
  margin: 0 auto;
  padding: 0px;
`;

const Logo = ({ type }) => {
  return (
    <StyledLogoContainer>
      <StyledLogo type={type} src='./logo.png' />
    </StyledLogoContainer>
  );
};

export default Logo;
