import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import NoData from '../../components/NoData/NoData';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  CreateButton,
  MessageBox,
  Dropdown,
  Button,
} from '../../components/FormElements';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { authData, formatCurrency } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const StyledDashboardContainer = styled.section`
  width: 95%;
  max-width: 1600px;
  height: auto;
  margin: 30px auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 900px) {
    display: block;
  }
`;

const StyledWhatsAppContainer = styled.section`
  width: 50%;
  height: auto;
  border: 1px solid #10846a;
  border-radius: 10px;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const StyledPlanDetailsContainer = styled.section`
  width: 50%;
  height: auto;
  border: 1px solid #10846a;
  border-radius: 10px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  background-color: #10846a;
  margin: 0px;
  padding: 15px;
  text-align: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

const StyledContextContainer = styled.section`
  padding: 20px;
  text-align: center;
  position: 'relative';
`;

const StyledPlanBuySection = styled.section`
  text-align: center;
`;

const StyledNoplanText = styled.h1`
  font-size: 20px;
  color: ${({ color }) => (color ? color : 'red')};
  margin: 0px;
  font-weight: normal;
  padding: 0px 0px 20px 0px;
`;

const StyledPlanSelectingSection = styled.section`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const StyledOrderResponse = styled.section`
  width: 100%;
  height: auto;
  text-align: center;
`;

const StyledOrderResponseStatusIcon = styled.section`
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  padding: 20px 0px 0px;
  color: ${({ color }) => (color ? color : '#545454')};
`;

const StyledWhatsAppSessionSection = styled.section`
  width: 100%;
  height: auto;
  text-align: center;
`;

const StyledImageSection = styled.section`
  width: 264px;
  height: 264px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledOrderResponseStatus = styled.h1``;

const Dashboard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [plans, setPlans] = useState([]);
  const [plansId, setPlansId] = useState('');
  const [showOrderResponse, setShowOrderResponse] = useState(false);
  const [orderResponseStatus, setOrderResponseStatus] = useState(false);
  const [whatsappIsLoading, setWhatsappIsLoading] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showLogoutMesssage, setShowLogoutMessage] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [wsMessage, setWsMessage] = useState('');

  const [startWhatsAppSessionCheck, setStartWhatsAppSessionCheck] =
    useState(false);
  const [isLoadingWS, setIsLoadingWS] = useState(false);
  const [responseStatusWS, setResponseStatusWS] = useState('');
  const [responseMessageWS, setResponseMessageWS] = useState('');
  const [responseDataWS, setResponseDataWS] = useState('');

  const { protocol, host } = window.location;
  const websocketHost =
    process.env.NODE_ENV === 'development'
      ? `192.168.1.2`
      : `api.${host.replace('www.', '')}`;
  const websocketPort = '80';
  let client;

  const {
    accountData: { firstName, lastName, mobileNumber, accountExpiry },
    authToken,
  } = authData.get();

  const isPlanExpiringToday =
    !isEmpty(accountExpiry) && moment(new Date()).isSame(accountExpiry, 'date');

  const isPlanExpired = isPlanExpiringToday
    ? false
    : !isEmpty(accountExpiry) &&
      moment(new Date()).isAfter(accountExpiry, 'date');

  const planExpiryDateDiff =
    !isEmpty(accountExpiry) &&
    moment(accountExpiry).diff(new Date(), 'days') + 1;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (result) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const { amount, orderId, clientOrdersId } = result;
    const options = {
      key: 'rzp_live_GR4SvsGa1syLUf',
      amount: amount.toString(),
      currency: 'INR',
      order_id: orderId,
      handler: (response) => {
        setIsLoading(true);
        setResponseStatus('');
        setResponseMessage('');
        API.put(
          `/transactions?id=${clientOrdersId}&orderId=${orderId}&razorpayPaymentId=${response.razorpay_payment_id}&razorpayOrderId=${response.razorpay_order_id}&razorpaySignature=${response.razorpay_signature}&status=success`
        )
          .then((response) => {
            const { status, message, data } = response.data;
            if (status === 'success') {
              setShowOrderResponse(true);
              setOrderResponseStatus(message);
              setTimeout(() => {
                window.location.href = '/';
              }, 3000);
            }
          })
          .catch((error) => {
            setResponseStatus(API_RESPONSE_TYPES.FAILURE);
            setResponseMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email: '',
        contact: mobileNumber,
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#11846a',
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {});
    paymentObject.open();
  };

  const readPlans = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/plans/?pageNumber=1&recordsPerPage=1000&planName=`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setPlans(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readWhatsAppSession = () => {
    // client = new W3CWebSocket(
    //   protocol === 'https:' ? `wss://${websocketHost}` : `ws://${websocketHost}`
    // );

    client = new W3CWebSocket(
      protocol === 'https:'
        ? `wss://${websocketHost}:${websocketPort}`
        : `ws://${websocketHost}:${websocketPort}`
    );

    client.onerror = (error) => {
      setIsLoadingWS(false);
      setResponseStatusWS('failed');
      setResponseMessageWS(
        'Looks like our server is firing up, Please try again in 30 seconds'
      );
      setResponseDataWS('');
    };

    client.onopen = () => {
      if (client.readyState === client.OPEN) {
        client.send(
          JSON.stringify({
            mobileNumber: mobileNumber,
            sessionToken: authToken,
          })
        );
      }
    };

    client.onmessage = (event) => {
      const processedData = JSON.parse(event.data);
      setIsLoadingWS(false);
      setResponseStatusWS(processedData.status);
      setResponseMessageWS(processedData.message);
      setResponseDataWS(processedData.data);
    };

    client.onclose = () => {
      setStartWhatsAppSessionCheck(false);
      setIsLoadingWS(false);
      setResponseStatusWS('');
      setResponseMessageWS('');
      setResponseDataWS('');
    };
  };

  const disconnectWebSocket = () => {
    if (client?.readyState === WebSocket.OPEN) {
      client && client?.close();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post(`/transactions/`, { plansId })
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          displayRazorpay(data);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const logoutWhatsAppSession = (e) => {
    e.preventDefault();
    setIsLoggingOut(true);
    setIsLoadingWS(true);
    setWhatsappIsLoading(true);
    setShowLogoutMessage(false);
    API.delete(`/whatsapp/logout-whatsapp-session`)
      .then((response) => {
        const { status, message } = response.data;
        setShowLogoutMessage(false);
        setWsMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
        }
      })
      .catch((error) => {
        setWsMessage(error.message);
      })
      .finally(() => {
        setIsLoggingOut(false);
        setWhatsappIsLoading(false);
      });
  };

  const checkWhatsappConnectionStatus = () => {
    setStartWhatsAppSessionCheck(true);
    setIsLoadingWS(true);
    setResponseStatusWS('');
    setResponseMessageWS('');
    setResponseDataWS('');
    readWhatsAppSession();
  };

  useEffect(() => {
    readPlans();
  }, [readPlans]);

  useEffect(() => {
    return () => disconnectWebSocket();
  }, []);

  const plansOptions = [{ title: '-- SELECT PLAN --', value: '' }];
  plans.forEach(({ id, planName, price }) =>
    plansOptions.push({
      title: `${planName} - ${formatCurrency(price)}`,
      value: id,
    })
  );

  const generatePlanMessage = () => {
    const {
      accountData: { accountExpiry },
    } = authData.get();

    if (!accountExpiry) {
      return (
        <>
          <StyledNoplanText>You have no active plan</StyledNoplanText>
          <StyledNoplanText>Please buy a plan to continue</StyledNoplanText>
        </>
      );
    }
    if (isPlanExpired) {
      return (
        <>
          <StyledNoplanText>
            {`You plan expired on ${accountExpiry}`}
          </StyledNoplanText>
          <StyledNoplanText>Please renew to continue</StyledNoplanText>
        </>
      );
    }
    if (isPlanExpiringToday) {
      return (
        <>
          <StyledNoplanText>Your plan is expiring today</StyledNoplanText>
          <StyledNoplanText>
            Please renew to avoid any uninterrupted services
          </StyledNoplanText>
        </>
      );
    }
    if (planExpiryDateDiff <= 7) {
      return (
        <>
          <StyledNoplanText>{`Your plan is expiring in ${planExpiryDateDiff} days`}</StyledNoplanText>
          <StyledNoplanText>
            Please renew to avoid any uninterrupted services
          </StyledNoplanText>
        </>
      );
    }
    if (accountExpiry) {
      return (
        <StyledNoplanText color='forestgreen'>
          {`Your plan is active and will expire on ${accountExpiry}`}
        </StyledNoplanText>
      );
    }
  };

  return (
    <StyledDashboardContainer>
      <StyledWhatsAppContainer>
        <StyledHeading>Whats App Status</StyledHeading>
        <StyledContextContainer>
          {!startWhatsAppSessionCheck ? (
            <>
              <Button onClick={checkWhatsappConnectionStatus}>
                Check Whatsapp Connection Status
              </Button>
            </>
          ) : (
            <>
              {isLoadingWS && <OverlayLoader showLoader={true} />}
              {responseDataWS === '' &&
                responseStatusWS !== '' &&
                responseMessageWS !== '' &&
                responseMessageWS !== 'Your whatsapp is connected & active' && (
                  <StyledNoplanText color='forestgreen'>
                    {responseMessageWS}
                  </StyledNoplanText>
                )}
              {responseMessageWS === 'Your whatsapp is connected & active' && (
                <>
                  <StyledNoplanText color='forestgreen'>
                    Your whats app account is connected
                  </StyledNoplanText>
                  <Button onClick={logoutWhatsAppSession}>Disconnect</Button>
                </>
              )}
              {responseDataWS !== '' && (
                <StyledWhatsAppSessionSection>
                  <StyledNoplanText>
                    Your whats app account is disconnected
                  </StyledNoplanText>
                  <StyledNoplanText>{responseMessageWS}</StyledNoplanText>
                  <StyledImageSection>
                    <img src={responseDataWS} alt='Whats App QR Code' />
                  </StyledImageSection>
                </StyledWhatsAppSessionSection>
              )}
            </>
          )}
        </StyledContextContainer>
        <StyledNoplanText
          color='red'
          style={{ fontSize: 14, textAlign: 'center' }}
        >
          Note: Your account setup will take 2 minutes on linking your whats app
          for the first time.
        </StyledNoplanText>
      </StyledWhatsAppContainer>
      <StyledPlanDetailsContainer>
        <StyledHeading>Your plan details</StyledHeading>
        <StyledContextContainer>
          {generatePlanMessage()}
          {(!accountExpiry ||
            isPlanExpiringToday ||
            isPlanExpired ||
            planExpiryDateDiff <= 7) && (
            <StyledPlanBuySection>
              <NoData
                status={
                  responseStatus !== API_RESPONSE_TYPES.FAILURE &&
                  !isLoading &&
                  plans.length === 0
                }
                message={`No plans found`}
              />
              {plans.length > 0 && (
                <>
                  {showOrderResponse ? (
                    <StyledOrderResponse>
                      <StyledOrderResponseStatusIcon
                        color={
                          orderResponseStatus === 'Transaction successful'
                            ? 'forestgreen'
                            : 'red'
                        }
                      >
                        {orderResponseStatus === 'Transaction successful' ? (
                          <FontAwesomeIcon icon={faCheckCircle} />
                        ) : (
                          <FontAwesomeIcon icon={faCircleXmark} />
                        )}
                      </StyledOrderResponseStatusIcon>
                      <StyledOrderResponseStatus>
                        {orderResponseStatus}
                      </StyledOrderResponseStatus>
                    </StyledOrderResponse>
                  ) : (
                    <StyledPlanSelectingSection>
                      <FormContainer>
                        <Form method='POST' action='#' onSubmit={onSubmit}>
                          <Fieldset>
                            <Label required>Select Plan</Label>
                            <Dropdown
                              placeholder='Please select plan'
                              value={plansId}
                              onChange={setPlansId}
                              options={plansOptions}
                              disabled={isLoading}
                            />
                          </Fieldset>
                          {responseStatus === API_RESPONSE_TYPES.FAILURE && (
                            <Fieldset>
                              <MessageBox
                                status={responseStatus}
                                message={responseMessage}
                              />
                            </Fieldset>
                          )}
                          <Fieldset>
                            <CreateButton
                              disabled={isLoading}
                              type='submit'
                              style={{ width: 'auto' }}
                            >
                              Buy Now
                            </CreateButton>
                          </Fieldset>
                          <OverlayLoader showLoader={isLoading} />
                        </Form>
                      </FormContainer>
                    </StyledPlanSelectingSection>
                  )}
                </>
              )}
            </StyledPlanBuySection>
          )}
        </StyledContextContainer>
      </StyledPlanDetailsContainer>
    </StyledDashboardContainer>
  );
};

export default withAppHOC(Dashboard);
