import {
  faHome,
  faGears,
  faUsers,
  faBoxArchive,
  faMessage,
  faListCheck,
  faCode,
  faBook,
  faPeopleGroup,
  faEnvelopesBulk,
  faBell,
} from '@fortawesome/free-solid-svg-icons';
import { ROUTE_TYPES, MODULES, PERMISSION_TYPES, VIEW_TYPES } from './index';
import Register from '../containers/Auth/Register';
import Login from '../containers/Auth/Login';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Profile from '../containers/Auth/Profile';
import ChangePassword from '../containers/Auth/ChangePassword';
import Dashboard from '../containers/Dashboard/Dashboard';
import WhatsAppConfiguration from '../containers/WhatsAppConfiguration/WhatsAppConfiguration';
import AccountsCreate from '../containers/Accounts/AccountsCreate';
import AccountsRead from '../containers/Accounts/AccountsRead';
import AccountsUpdate from '../containers/Accounts/AccountsUpdate';
import PlansCreate from '../containers/Plans/PlansCreate';
import PlansRead from '../containers/Plans/PlansRead';
import PlansUpdate from '../containers/Plans/PlansUpdate';
import ScrollMessage from '../containers/ScrollMessage/ScrollMessage';
import SendBulkMessage from '../containers/SendMessages/SendBulkMessage';
import SendGroupMessage from '../containers/SendMessages/SendGroupMessage';
import Messages from '../containers/Messages/Messages';
import Transactions from '../containers/Transactions/Transactions';
import APISettings from '../containers/APISettings/APISettings';
import APIDocumentation from '../containers/APIDocumentation/APIDocumentation';

const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
const {
  DASHBOARD,
  WHATSAPP_CONFIGURATION,
  ACCOUNTS,
  PLANS,
  SCROLL_MESSAGE,
  SEND_BULK_MESSAGE,
  SEND_GROUP_MESSAGE,
  MESSAGES,
  TRANSACTIONS,
  API_SETTINGS,
  API_DOCUMENTATION,
} = MODULES;
const { CREATE, READ, UPDATE } = PERMISSION_TYPES;
const { TABLE, GRID } = VIEW_TYPES;

const ROUTES = [
  {
    path: '/',
    title: 'Home',
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: DASHBOARD,
    permissionRequired: READ,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
    exact: true,
  },
  {
    path: '/register',
    title: 'Register',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Register,
    hideInNavigation: true,
  },
  {
    path: '/login',
    title: 'Login',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: '/profile',
    title: 'Profile',
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
  },
  {
    path: '/whatsapp-app-configuration',
    title: 'Whats App Configuration',
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: WHATSAPP_CONFIGURATION,
    permissionRequired: READ,
    component: WhatsAppConfiguration,
    viewType: GRID,
  },
  {
    path: '/accounts',
    title: 'Accounts',
    icon: faUsers,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ACCOUNTS,
    permissionRequired: READ,
    component: AccountsRead,
    viewType: TABLE,
    subRoutes: [
      {
        path: '/accounts/create',
        title: 'Create Account',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS,
        permissionRequired: CREATE,
        component: AccountsCreate,
        hideInNavigation: true,
        viewType: GRID,
      },
      {
        path: '/accounts/update/:id',
        title: 'Update Account',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS,
        permissionRequired: UPDATE,
        component: AccountsUpdate,
        hideInNavigation: true,
        viewType: GRID,
      },
    ],
  },
  {
    path: '/plans',
    title: 'Plans',
    icon: faBoxArchive,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: PLANS,
    permissionRequired: READ,
    component: PlansRead,
    viewType: TABLE,
    subRoutes: [
      {
        path: '/plans/create',
        title: 'Create Plan',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PLANS,
        permissionRequired: CREATE,
        component: PlansCreate,
        hideInNavigation: true,
        viewType: GRID,
      },
      {
        path: '/plans/update/:id',
        title: 'Update Plan',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PLANS,
        permissionRequired: UPDATE,
        component: PlansUpdate,
        hideInNavigation: true,
        viewType: GRID,
      },
    ],
  },
  {
    path: '/scroll-message',
    title: 'Scroll Message',
    icon: faBell,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: SCROLL_MESSAGE,
    permissionRequired: UPDATE,
    component: ScrollMessage,
    viewType: GRID,
  },
  {
    path: '/send-bulk-message',
    title: 'Send Bulk Message',
    icon: faEnvelopesBulk,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: SEND_BULK_MESSAGE,
    permissionRequired: CREATE,
    component: SendBulkMessage,
    viewType: GRID,
  },
  {
    path: '/send-group-message',
    title: 'Send Group Message',
    icon: faPeopleGroup,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: SEND_GROUP_MESSAGE,
    permissionRequired: CREATE,
    component: SendGroupMessage,
    viewType: GRID,
  },
  {
    path: '/messages',
    title: 'Messages',
    icon: faMessage,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: MESSAGES,
    permissionRequired: READ,
    component: Messages,
    viewType: TABLE,
  },
  {
    path: '/transactions',
    title: 'Transactions',
    icon: faListCheck,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: TRANSACTIONS,
    permissionRequired: READ,
    component: Transactions,
    viewType: TABLE,
  },
  {
    path: '/api-settings',
    title: 'API Settings',
    icon: faCode,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: API_SETTINGS,
    permissionRequired: READ,
    component: APISettings,
    viewType: GRID,
  },
  {
    path: '/api-documentation',
    title: 'API Documentation',
    icon: faBook,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: API_DOCUMENTATION,
    permissionRequired: READ,
    component: APIDocumentation,
    viewType: GRID,
  },
];

export { ROUTES };
