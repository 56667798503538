import React from 'react';
import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import Accordination from '../../components/Accordination/Accordination';
import { TableBuilder } from '../../components/TableElements';
import APIConfig from '../../constants/api.config';

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const APIDocumentation = (props) => {
  const { API_BASE_URL } = APIConfig;

  return (
    <>
      <PageHeading {...props} />
      <Accordination header='Send Message API' autoExpanded={true}>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{' '}
              <code>{`${API_BASE_URL}/whatsapp/send-message?apiKey=[apiKey]&apiToken=[apiToken]&receiversNumber=[receiversNumber]&message=[message]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'apiKey',
                  description: 'api key provided by us',
                  fieldType: 'Mandatory',
                },
                {
                  field: 'apiToken',
                  description: 'api token provided by us',
                  fieldType: 'Mandatory',
                },
                {
                  field: 'receiversNumber',
                  description:
                    'Mobile number to which the message will be sent',
                  fieldType: 'Mandatory',
                },
                {
                  field: 'message',
                  description: 'Your message to the receiver',
                  fieldType: 'Mandatory',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id='json-pretty'
              data={JSON.stringify({
                status: 'success/failed/pending',
                message: 'Information',
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </Accordination>
    </>
  );
};

export default withAppHOC(APIDocumentation);
