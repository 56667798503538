import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const AccountsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { firstName, lastName, mobileNumber, accountType, accountStatus } =
      originalFormData;
    setFirstName(firstName);
    setLastName(lastName);
    setMobileNumber(mobileNumber);
    setAccountType(accountType);
    setAccountStatus(accountStatus);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounts/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            firstName,
            lastName,
            mobileNumber,
            accountType,
            accountStatus,
          } = data[0];
          setFirstName(firstName);
          setLastName(lastName);
          setMobileNumber(mobileNumber);
          setAccountType(accountType);
          setAccountStatus(accountStatus);
          setOriginalFormData({
            firstName,
            lastName,
            mobileNumber,
            accountType,
            accountStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      firstName,
      lastName,
      mobileNumber,
      accountType,
      accountStatus,
    };
    API.put(`/accounts/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/accounts';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  placeholder='Please enter your first name'
                  value={firstName}
                  onChange={setFirstName}
                  maxLength={100}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  placeholder='Please enter your last name'
                  value={lastName}
                  onChange={setLastName}
                  maxLength={100}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter your mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Account Type</Label>
                <Dropdown
                  placeholder='Account Type'
                  value={accountType}
                  onChange={setAccountType}
                  options={[
                    {
                      title: '-- SELECT ACCOUNT TYPE --',
                      value: '',
                    },
                    {
                      title: 'Admin',
                      value: 'Admin',
                    },
                    {
                      title: 'User',
                      value: 'User',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Status</Label>
                <Dropdown
                  placeholder='Account Status'
                  value={accountStatus}
                  onChange={setAccountStatus}
                  options={[
                    {
                      title: '-- SELECT ACCOUNT STATUS --',
                      value: '',
                    },
                    {
                      title: 'Active',
                      value: 'Active',
                    },
                    {
                      title: 'Inactive',
                      value: 'Inactive',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AccountsUpdate);
