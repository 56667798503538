import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { ROUTES } from '../../constants/routes.config';
import { checkRoutePermission } from '../../utils';

const StyledNavigation = styled.nav`
  width: 100%;
  height: auto;
  background-color: #11846a;
  margin: 0px;
  padding: 0px;
  display: block;
  @media (max-width: 600px) {
    display: none;
    &.show {
      display: block;
    }
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      margin: 0px;
      padding: 0px;
      position: relative;
      transition: background-color 0.5s;
      border-right: 1px solid
        #0a6d57;

      @media (max-width: 600px) {
        display: block;
      }
      &:hover {
        background-color: #0a6d57;
        @media (max-width: 600px) {
          background-color: transparent;
        }
      }
      &:hover > ul {
        display: block;
      }
      a {
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        margin: 0px;
        padding: 12px 20px;
        text-decoration: none;
        display: block;
        &.active {
          background-color: #0a6d57;
          @media (max-width: 600px) {
            background-color: transparent;
          }
        }
      }
      ul {
        position: absolute;
        left: -1px;
        display: none;
        list-style: none;
        width: auto;
        min-width: 300px;
        height: auto;
        margin: 0px;
        padding: 0px;
        background-color: #0078ce;
        z-index: 999999;
        @media (max-width: 600px) {
          display: block;
          position: static;
        }
        li {
          display: block;
          margin: 0px;
          padding: 0px;
          border-right: 0px;
          border-bottom: 1px solid
            #0067b0;
          &:hover {
            background-color: #0067b0;
          }
          a {
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
            margin: 0px;
            padding: 12px 15px;
            text-decoration: none;
            display: block;
            transition: background-color 0.5s;
            @media (max-width: 600px) {
              padding-left: 40px;
            }
            &.active {
              background-color: #0067b0;
            }
          }
        }
      }
    }
  }
`;

const Navigation = ({ showNav }) => {
  const onClickDummyLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledNavigation className={showNav ? 'show' : ''}>
      {!isEmpty(ROUTES) && (
        <ul>
          {ROUTES.map(
            (item, index) =>
              item.hideInNavigation !== true &&
              item.moduleName &&
              item.permissionRequired &&
              checkRoutePermission(item) && (
                <li key={`main-route-${index}`}>
                  <NavLink
                    onClick={item.isDummy && onClickDummyLink}
                    to={item.path}
                    title={item.title}
                  >
                    <FontAwesomeIcon icon={item.icon} /> {item.title}
                  </NavLink>
                  {item.subRoutes && item.subRoutes.length > 0 && (
                    <ul>
                      {item.subRoutes.map((sitem, index) => {
                        return (
                          sitem.hideInNavigation !== true &&
                          (item.moduleName && item.permissionRequired
                            ? checkRoutePermission(sitem)
                            : true) && (
                            <li key={`sub-route-${index}`}>
                              <NavLink
                                to={sitem.path}
                                title={sitem.title}
                                className={({ isActive }) =>
                                  isActive ? `active` : ``
                                }
                              >
                                {sitem.title}
                              </NavLink>
                            </li>
                          )
                        );
                      })}
                    </ul>
                  )}
                </li>
              )
          )}
        </ul>
      )}
    </StyledNavigation>
  );
};

export default Navigation;
